.grid-section1 {
  grid-area: "grid-section1";
  display: grid;
}

#about_div1 {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas: "grid-section1 grid-section1";
  /* text-align: center;  */
}

#about_div1 .grid-section1 img {
  /* border: 20px solid #ff6969; */
  margin: auto;
  border-radius: 10px;
  width: 96%; 
}

#about_div1 .grid-section1 .partyLogo {
  width: 100px;
  margin-bottom: 10px;
  border-radius: 800px;
  aspect-ratio: 1/1;
}

#about_div1 .grid-section2 {
  padding: 2% 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* #about_div1 .grid-section2 .main_form_center {

} */
#about_div1 .grid-section2 h3 {
  color: #ff6969;
}
#about_div1 .grid-section2 h1 {
  color: #3c486b;
}
#about_div1 .grid-section2 p {
  color: #3c486b;
  text-align: justify;
}

@media screen and (max-width: 400px) {
  #about_div1 .grid-section2 h3 {
    color: #ff6969;
  }
  #about_div1 .grid-section2 h1 {
    color: #3c486b;
  }
  #about_div1 .grid-section2 p {
    color: #3c486b;
    text-align: justify;
  }
}

#about_div1 .grid-section2 button {
  margin: 28px 2%;
  padding: 8px 25px;
  border: none;
  background-color: #3c486b;
  color: white;
}

#about_div1 .grid-section2 button:hover {
  background-color: RGB(20 20 20);
}

@media screen and (max-width: 1080px) {
  #about_div1 {
    grid-template-columns: 100% !important;
    grid-template-areas: "grid-section1" "grid-section1";
    text-align: center;
  }

  #about_div1 .grid-section1 img {
    /* border: 20px solid #ff6969; */
    /* margin: 0 5%; */
    width: 90%;
    max-width: 400px;
    align-self: center;
  }
}
