.onlineDarbar_div1 {
  display: grid;
  grid-template-columns: 40% 60% !important;
  background: white;
  padding: 30px 0px;
}
/* .onlineDarbar_div1 .grid-section1 {
  display: flex;
  justify-content: center;
} */

.header_web_form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background: linear-gradient(rgba(0, 0, 0, 0.73), rgb(0 0 0 / 0%));
  /* background-color: black; */
}

.header_web_form .partyLogo {
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  margin: 10px;
}

.header_web_form h1 {
  color: white;
  font-weight: 500;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal, auto;
  text-transform: uppercase;
  -webkit-text-decoration: none solid rgb(60, 72, 107);
  text-decoration: none solid rgb(60, 72, 107);
  text-align: center;
  text-indent: 0px;
  line-height: 48px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  margin: 0px 0px 8px;
  padding: 0px;
  font-size: 25px;
}

.leader_main_image {
  display: flex;
  justify-content: center;
}

.leader_main_image img {
  border-radius: 10px;
  margin: 20px;
}

@media screen and (min-width: 768px) {
  .header_web_form .partyLogo {
    width: 60px;
  }

  .header_web_form h1 {
    font-size: 40px;
  }
}

.onlineDarbarMainForm {
  border-radius: 20px;
  overflow: hidden;
}

.online-darbar-form {
  width: 100%;
  padding: 7px 30px;
  border: none;
  /* box-shadow: 0px 1px 3px -1px #3a3838; */

  color: #747474;
  /* margin: 10px 2%; */
  border-radius: 77px;
  background: #f3f3f3;
  font-family: "Open Sans", sans-serif;

  font-weight: 500;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-indent: 0px;

  font-size: 1.1rem;

  /* max-width: 500px; */
}

.online-darbar-form:focus {
  outline: none !important;
  /* border:1px solid #dddddd; 
        box-shadow: 0px 2px 4px -1px #3a3838; */
}

form .half-input {
  display: inline-block;
  width: 46%;
  margin: 10px 2%;
  padding: 15px;
}

form .half-input label {
  margin: 30px;
  font-size: 20px;
  color: #3c486b;
}

@media screen and (max-width: 768px) {
  form .half-input {
    width: 96%;
    margin: 10px 2%;
  }
}

form button {
  margin: 28px 0;
  padding: 8px 25px;
  border: none;
  background-color: #3c486b;
  color: white;
  width: 96%;
  margin: 10px 2%;
  border-radius: 50px;
}

.logo_heading_custom {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 50px 20px;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #b4b4b3;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b4b4b3;
}

::placeholder {
  color: #b4b4b3;
}

/* body{
    background-color: #f3f3f3 ;
  } */

/* =============== social media section ======================== */
.social-media-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-media {
  padding: 20px;
  text-align: center;
}
.social-media img {
  max-width: 100px;
  width: 70%;
  aspect-ratio: 1/1;
  /* border-radius: 50%; */
}
/* =============== social media section ======================== */
