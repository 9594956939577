@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");

* {
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

button,a{
  cursor: pointer;
}

.home_page_heading h1 {
  color: #3c486b;
  text-align: center;
  font-family: "Anton", sans-serif !important;
  text-transform: uppercase;
  font-weight: 500;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-transform: uppercase;
  text-decoration: none solid rgb(60, 72, 107);
  text-align: center;
  text-indent: 0px;

  line-height: 48px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  margin: 0px 0px 8px;
  padding: 0px;

  font-size: 40px;
}

.home_page_heading h3 {
  color: #ff6969;
  text-align: center;
  font-family: Open Sans, sans-serif;
  text-transform: uppercase;
  font-size: 28px;

  line-height: 33.6px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  margin: 0px 0px 8px;
  padding: 0px;

  font-weight: 500;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-stretch: 100%;
  font-variation-settings: normal;
  font-feature-settings: normal;
  text-transform: uppercase;
  text-decoration: none solid rgb(255, 105, 105);
  text-align: center;
  text-indent: 0px;
}

@media screen and (min-width: 912px) {
}
