.success-msg-box {
  background-color: rgba(0, 0, 0, 0.50);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1,h3{
    color: rgb(60,72,107);
}
.success-msg-box .msg-box {
  background-color: white;
  width: 360px; 
  border-radius: 10px;
  text-align: center;
}

.success-msg-box button {
  background: blue;
  padding: 5px 20px;
  border-radius: 5px;
  color: white;
  font-size: 20px;
  margin: 20px;
}

.success-msg-box h3 {
  font-size: 20px;
  margin: 10px;
}

.img-thanks{
  margin: 20px;
}

.img-thanks img{ 
  width: 200px;
}